import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";

import Layout from "../components/Layout";

export const TrainingPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <section className="home-hero">
      <InView threshold={0.2}>
        {({ ref, inView }) => (
          <motion.div
            ref={ref}
            initial={{ opacity: 0, transform: "translate(-50px)" }}
            animate={
              inView
                ? { opacity: 1, transform: "translate(0px)" }
                : { opacity: 1 }
            }
            transition={{
              duration: 0.5,
              delay: 0.25,
            }}
            className="ontopyo"
          >
            <h1
              data-scroll
              data-scroll-direction="horizontal"
              data-scroll-speed="2"
              data-scroll-position="top"
              data-scroll-delay="0.2"
            >
              Pilot
              <br />
              Training
            </h1>
          </motion.div>
        )}
      </InView>

      <div className="scroll-line">
        <div className="center" />

        <div data-scroll data-scroll-speed="-1" className="marker" />
      </div>

      <div className="video-container">
        <div className="video-overlay" />
        <img src="../img/hero-training.jpg" />
      </div>
    </section>
    <div className="contact-heli">
      <div className="intro-heli">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.h5
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
            >
              <span data-scroll data-scroll-speed="1.5" data-scroll-delay="0.2">
                CAA approved to conduct PPL and Type-Rating Instruction on a
                variety of helicopters.
              </span>
            </motion.h5>
          )}
        </InView>
      </div>
    </div>
    <div className="block-heli-pad">
      <div className="twoup">
        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <p>
                The type-rating/conversion course is designed to familiarise the
                pilot with the differences in aerodynamics, mechanics,
                technology, operation and performance, together with an
                understanding of the differences in emergency procedures. The
                course length is specific to each aircraft. The time that you
                will require depends very much on the individual and their level
                of experience. The instructor will ensure that you are ready for
                your test and to fly the new type safely and with confidence.
              </p>
            </motion.div>
          )}
        </InView>

        <InView threshold={0.2}>
          {({ ref, inView }) => (
            <motion.div
              ref={ref}
              initial={{ opacity: 0 }}
              animate={inView ? { opacity: 1 } : { opacity: 1 }}
              transition={{
                duration: 0.25,
                delay: 0.1,
              }}
              className="heli-block"
            >
              <div className="bullets-container">
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <div classname="stack">
                    <p>First turbine conversion</p>
                    <span>Minimum of 5-hours flight training</span>
                  </div>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <div classname="stack">
                    <p>CAdditional turbine types</p>
                    <span>Minimum of 2-hours flight training*</span>
                  </div>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <div classname="stack">
                    <p>First multi-engine type</p>
                    <span>Minimum of 8-hours flight training</span>
                  </div>
                </div>
                <div className="bullets">
                  <img src="../img/bullet.svg" />
                  <div classname="stack">
                    <p>Additional multi-engine</p>
                    <span>Minimum of 5-hours flight training</span>
                  </div>
                </div>
                <span>* Subject to approval and students experience/pre-entry requirements</span>
              </div>
            </motion.div>
          )}
        </InView>
      </div>
    </div>
    <div className="heli-grey">
      <div className="twoup">
        <div className="item">
          <div className="item-image-holder">
            <img
              data-scroll
              data-scroll-speed=".5"
              data-scroll-delay="0.2"
              className="ig25"
              src="../img/train-1.jpg"
            />
            <img
              data-scroll
              data-scroll-speed="2.5"
              data-scroll-delay="0.2"
              className="ig25"
              src="../img/train-2.jpg"
            />
          </div>
        </div>
        <div className="item" data-scroll data-scroll-speed="4.5" data-scroll-delay="0.2">
        <p>
          The courses are generally run over a 3-day (for single engine) or 5-day (multi-engine) period.  Excel Helicopters is also a registered training facility enabling us to offer ab-initio training for the issue of a Private Pilot Licence (Helicopter) You will need to take a Proficiency Check (PC) test annually on each type that you fly with one of our examiners to maintain currency.
          </p>
          <Link to="/contact">Contact Us</Link>
        </div>
      </div>
    </div>
  </div>
);

TrainingPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const TrainingPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <TrainingPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

TrainingPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default TrainingPage;

export const pageQuery = graphql`
  query TrainingPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            text
          }
          heading
          description
        }
      }
    }
  }
`;
